import { emptySplitApi } from "../emptySplit-api";

export const courseApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    course: builder.query({
      query: () => `api/v1/course`,
    }),
    courseById: builder.query({
      query: (id) => `api/v1/course/${id}`,
    }),
    
  }),
  overrideExisting: false,
});

export const { useCourseQuery,useCourseByIdQuery} = courseApi;



