// DUCKS pattern
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: undefined,
};

const videoCurrent = createSlice({
  name: 'videoCurrent',
  initialState,
  reducers: {
     Current(state, action) {
      state.value = action.payload;
    },
  },
});

export const {Current} = videoCurrent.actions;
export default videoCurrent.reducer;
