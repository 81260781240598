import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {emptySplitApi} from 'Features/emptySplit-api'
import videoCurrentReducer from "Features/video/videoCurrent-slice";
import csrfTokenReducer from "Features/csrf/csrf-slice"

export const store = configureStore({
  reducer: {
    videoCurrent:videoCurrentReducer,
    csrfToken:csrfTokenReducer,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(emptySplitApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);


