import React from "react";
import { StyledIcon } from "./Styles";
import { fontIconCodes } from "./icons";

const Icon = ({ iconName, className, size = "16", color = "white", hide=false ,...rest} ) => ( 
    <StyledIcon
      {...rest}
      size={size}
      hide={hide}
      color={color}
      className={className}
      code={fontIconCodes[iconName]}
    />
  );


export default Icon;
