import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// initialize an empty api service that we'll inject endpoints into as needed
export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    credentials: "include",
    prepareHeaders: async (headers,{getState}) => {

   const token =  getState().csrfToken.value

      if (token || token !== undefined) {
        headers.set('X-CSRF-Token', token)
      }

      return headers
    },
  }),
  endpoints: () => ({}),
});
