import React from "react";
import {

} from "./Styles";
import {
  Modal,
  ModalContents,
  ModalOpenButton,
} from "shared/components/Modal/Modal";
import LabsModal from "Platform/Common/Modals/LabsModal";

const ModalContainer = ({children,title,id ,img}) => {

  return (
    <>
      <Modal key={id}>
        <ModalOpenButton>
        {children}
        </ModalOpenButton>
        <ModalContents
          size="lg"
          height="auto"
          aria-label="upload labs"
          title={title}
        >
          <LabsModal src={img}/>
        </ModalContents>
      </Modal>
    </>
  );
};

export default ModalContainer;
