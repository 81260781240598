import React from "react";
import { StyledTitle, StyledSectionMotivation,StyledSmallText } from "./Styles";
import {StyledUl} from '../lib'
import typingMan from "App/assets/svg/typingMan.svg";
import UploadItems from "./UploadItems";

const UploadProjectsModal = () => {
  return (
    <>
      <StyledTitle>General Information</StyledTitle>
      <StyledUl>
        <li>
          If a document is submitted, all documents must be submitted in a pdf
          format only.
        </li>
        <li>
          If a project is submitted, the entire code must be submitted in an
          orderly manner with comments on the functions.
        </li>
        <li>
          Any submission both if it is a project or if it is a document should
          be submitted in a zip file only.
        </li>
        <li>
          The site staff will take up to a week to go over the project or
          document you submitted and must be patiently assisted.
        </li>
      </StyledUl>
      <StyledSectionMotivation>
        <img src={typingMan} alt="Logo" style={{ width: "15rem" }} />
        <StyledTitle>
          Hard work is the right way to success,
          <br />
          Keep submitting projects and doing all the chores!
        </StyledTitle>
      </StyledSectionMotivation>
      <StyledTitle>Please select tasks from the list</StyledTitle>
      <StyledSmallText >You can send more than one job at a time*</StyledSmallText>
      <UploadItems/>
    </>
  );
};

export default UploadProjectsModal;
