import {Dialog as ReachDialog} from '@reach/dialog'
import styled from 'styled-components'
import {color} from 'shared/utils/styles'

export const CircleButton = styled.button`
{
  border-radius: 30px;
  padding: 0;
  width: 25px;
    height: 25px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: ${color.primary};
border: 1.5px solid hsl(154deg 58% 51%);

  cursor: pointer;
}
`
export const Dialog = styled(ReachDialog)`
z-index:100;
position:fixed;
margin:auto;
top:0;
right:0;
left:0;
bottom:0;
background:${color.backgroundMedium};
max-height:80vh;
height:${props=>props.height};
  max-width:${props=>props.width};
  border-radius: 15px;
  padding-bottom: 3.5em;
  border: 1px solid  ${color.primary};
  box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.2);
  @media (max-width: 991px): {
    width:100%;
  }
`

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid ${color.transparentGray35};
& h3{
  font-size:1rem;
}
`

export const StyledWrapper = styled.div`
padding:20px 5% 10px 5%;
`



export const StyledScorllWrapper = styled.div`
height:96%;
width:99%;
position:absolute;
top:0;
left:0;
right:0;
bottom:0;
overflow-y:scroll;
margin:auto;
`
