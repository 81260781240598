import React, { useState } from "react";
import {
  StyledBoxWrapper,
  StyledTopWrapper,
  StyledUploadSection,
  StyledChooseFile,
  StyledName,
  StyledDoneTask
} from "./Styles";
import Icon from "shared/components/Icon/Icon";
import cloud from "App/assets/svg/cloud.svg";
import document from "App/assets/svg/document.svg";
import Button from "shared/components/Button";

const UploadContentBox = ({ name, isDone }) => {
  const [fileName, SetfileName] = useState("No file was chosen");

  return (
    <StyledBoxWrapper>
      <StyledTopWrapper>
        <Icon size={22} color="primary" iconName="info" />
      </StyledTopWrapper>
      <StyledName isDone={isDone}>{name}</StyledName>
      <div>
        <img
          isDone={isDone}
          style={{ height: isDone ? "80px" : "35px" }}
          src={isDone ? document : cloud}
          alt="upload svg cloud"
        />
      </div>
      {isDone ? (
        <StyledDoneTask>Task Sent!</StyledDoneTask>
      ) : (
        <>
          <StyledUploadSection>
            <StyledChooseFile variant="transparentGray45">Select file</StyledChooseFile>
            <h5>{fileName}</h5>
          </StyledUploadSection>
          <Button className="btn_glow">Upload</Button>
        </>
      )}
    </StyledBoxWrapper>
  );
};

export default UploadContentBox;
