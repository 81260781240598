import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: undefined,
};

const csrfToken = createSlice({
  name: 'csrfToken',
  initialState,
  reducers: {
    csrf(state, action) {
      state.value = action.payload;
    },
  },
});

export const {csrf} = csrfToken.actions;
export default csrfToken.reducer;
