import styled from "styled-components/macro";
import NoFormSelect from "shared/components/NoFormSelect/NoFormSelect";
import Button from "shared/components/Button/Button";

export const StyledWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    height:48px;
    align-items: center;

`;

export const StyledTitleNoFormSelect = styled(NoFormSelect)`
justify-self: end;
`;

export const StyledTitleButton = styled(Button)`
height: 42px;
width: 150px;
justify-self: end;
`;

export const StyledHeaderTitle=styled.h1`
font-weight: 500;
font-size: 16px;
line-height: 18px;
color: #FFFFFF;
text-transform:uppercase;

`