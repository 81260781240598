import { css } from "styled-components";
import theme from "styled-theming";

export const direction = theme("dir", {
  rtl: "rtl",
  ltr: "ltr",
});

export const color = {
  primary: "hsl(154deg 58% 51%)", // green-tourquize
  primaryGlow:" linear-gradient(to left,hsl(156deg 100% 84%),hsl(156deg 100% 49%));",
  primaryBright: "#5fffba", //green-brighter
  transparent: "transparent",
  yellow_gold:"hsl(39deg 100% 57%)",
  success: "hsl(154deg 58% 51%)", // green
  danger: "#E13C3C", // red
  warning: "#F89C1C", // orange
  secondary: "#F4F5F7", // light grey
  white: "#fff", //normal white
  transparentGray15: "hsl(0deg 0% 50% / 0.15)",
  transparentGray35: "hsl(0deg 0% 50% / 0.35)",
  transparentGray45:"hsl(0deg 0% 75%)",

  textPrimary: "#F9F9F9",
  textDarkest: "#172b4d",
  textDark: "#42526E",
  textMedium: "#5E6C84",
  textLight: "#F9F9F9",
  textLink: "#0052cc",

  backgroundDark_light: "#202226",
  backgroundDarkPrimary: "#000000",
  backgroundMedium: "#151618",
  backgroundLight: "#ebecf0",
  backgroundLightest: "#3F4147", //greyish
  backgroundUltraLightest: "#5e616b", // ultra greyish
  backgroundLightPrimary: "#D2E5FE",
  backgroundLightSuccess: "#E4FCEF",
  backgroundGradient: `linear-gradient(#17252C,#060606)`,

  borderLightest: "hsl(0deg 0% 100% / 20%)",
  borderLight: "#C1C7D0",
  borderInputFocus: "#4c9aff",
};

export const spacing = {
  base_spacing: "10px",
  half_spacing: "5px",
};

export const breakpoints = {
  mob_start: "372px",
  palm_start: "420px",
  lap_start: "768px",
  desk_start: "990px",
  wide_start: "1200px",
  page_width: "1600px",
};
// export const issueTypeColors = {
//   [IssueType.TASK]: '#4FADE6', // blue
//   [IssueType.BUG]: '#E44D42', // red
//   [IssueType.STORY]: '#65BA43', // green
// };

// export const issuePriorityColors = {
//   [IssuePriority.HIGHEST]: '#CD1317', // red
//   [IssuePriority.HIGH]: '#E9494A', // orange
//   [IssuePriority.MEDIUM]: '#E97F33', // orange
//   [IssuePriority.LOW]: '#2D8738', // green
//   [IssuePriority.LOWEST]: '#57A55A', // green
// };

// export const issueStatusColors = {
//   [IssueStatus.BACKLOG]: color.textDark,
//   [IssueStatus.INPROGRESS]: '#fff',
//   [IssueStatus.SELECTED]: color.textDark,
//   [IssueStatus.DONE]: '#fff',
// };

// export const issueStatusBackgroundColors = {
//   [IssueStatus.BACKLOG]: color.backgroundMedium,
//   [IssueStatus.INPROGRESS]: color.primary,
//   [IssueStatus.SELECTED]: color.backgroundMedium,
//   [IssueStatus.DONE]: color.success,
// };

export const sizes = {
 Primarycontainer :1200
};

export const zIndexValues = {
  modal: 1000,
  dropdown: 101,
  nav: 100,
};

export const font = {
  regular: 'font-family: "Open Sans", Arial, sans-serif;',
  medium: 'font-family:"Open Sans", Arial, sans-serif;',
  bold: 'font-family:"Open Sans", Arial, sans-serif;',
  black: 'font-family:"Open Sans", Arial, sans-serif;',
  size: (size) => `font-size: ${size}px;`,
};

export const mixin = {
  // darken: (colorValue, amount) =>
  //   Color(colorValue)
  //     .darken(amount)
  //     .string(),
  // lighten: (colorValue, amount) =>
  //   Color(colorValue)
  //     .lighten(amount)
  //     .string(),
  // rgba: (colorValue, opacity) =>
  //   Color(colorValue)
  //     .alpha(opacity)
  //     .string(),
  boxShadowMedium: css`
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  `,
  boxShadowDropdown: css`
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.31) 0px 0px 1px;
  `,
  truncateText: ({
    clamp = 4,
  } = {}) => css`
    overflow: hidden;
    text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp:  ${clamp} ;
  line-clamp: ${clamp};
  -webkit-box-orient: vertical;
  `,
  clickable: css`
    cursor: pointer;
    user-select: none;
  `,
  hardwareAccelerate: css`
    transform: translateZ(0);
  `,
  cover: css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `,
  placeholderColor: (colorValue) => css`
    ::-webkit-input-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    :-moz-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    ::-moz-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
    :-ms-input-placeholder {
      color: ${colorValue} !important;
      opacity: 1 !important;
    }
  `,
  scrollableY: css`
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  `,
  customScrollbar: ({
    width = 8,
    background = color.backgroundMedium,
  } = {}) => css`
    &::-webkit-scrollbar {
      width: ${width}px;
    }
    &::-webkit-scrollbar-track {
      background: none;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 99px;
      background: ${background};
    }
  `,
  backgroundImage: (imageURL) => css`
    background-image: url("${imageURL}");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: ${color.backgroundLight};
  `,
  link: (colorValue = color.textLink) => css`
    cursor: pointer;
    color: ${colorValue};
    ${font.medium}
    &:hover, &:visited, &:active {
      color: ${colorValue};
    }
    &:hover {
      text-decoration: underline;
    }
  `,
  tag: (
    background = color.backgroundMedium,
    colorValue = color.textDarkest
  ) => css`
    display: inline-flex;
    align-items: center;
    height: 24px;
    padding: 0 8px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    color: ${colorValue};
    background: ${background};
    ${font.bold}
    ${font.size(12)}
    i {
      margin-left: 4px;
    }
  `,
};
