import * as React from "react";
import VisuallyHidden from "shared/components/VisuallyHidden";
import { Dialog, CircleButton, StyledHeader,StyledWrapper,StyledScorllWrapper } from "./Styles";

const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach((fn) => fn && fn(...args));

const handleBackgroundOpacity = (isOpen) => {
  document.getElementById("root").style.opacity = isOpen ? "0.2" : "1";
};

const ModalContext = React.createContext();

function Modal(props) {
  const [isOpen, setIsOpen] = React.useState(false);

  return <ModalContext.Provider value={[isOpen, setIsOpen]} {...props} />;
}

function ModalDismissButton({ children: child }) {
  const [, setIsOpen] = React.useContext(ModalContext);
  return React.cloneElement(child, {
    onClick: callAll(() => {
      setIsOpen(false);
      handleBackgroundOpacity(false);
    }, child.props.onClick),
  });
}

function ModalOpenButton({ children: child }) {
  const [, setIsOpen] = React.useContext(ModalContext);
  return React.cloneElement(child, {
    onClick: callAll(() => {
      setIsOpen(true);
      handleBackgroundOpacity(true);
    }, child.props.onClick),
  });
}


function ModalContentsBase({ size = "sm",height = "auto", ...props }) {
  const [isOpen, setIsOpen] = React.useContext(ModalContext);

  const sizes={
    sm:"450px",
    md:"750px",
    lg:"1000px"
  }
  return (
    <Dialog
    aria-label="node"
      width={sizes[size]}
      height={height}
      isOpen={isOpen}
      onDismiss={() => {
        setIsOpen(false);
        handleBackgroundOpacity(false);
      }}
      {...props}
    />
  );
}

function ModalContents({ title, children, ...props }) {
  return (
    <ModalContentsBase {...props}>
    <StyledScorllWrapper>
      <StyledHeader>
        <h3>{title}</h3>
        <ModalDismissButton>
          <CircleButton>
            <VisuallyHidden>Close</VisuallyHidden>
            <span aria-hidden>×</span>
          </CircleButton>
        </ModalDismissButton>
      </StyledHeader>
        <StyledWrapper>
        {children}
        </StyledWrapper>
</StyledScorllWrapper>
    </ModalContentsBase>
  );
}

export { Modal, ModalDismissButton, ModalOpenButton, ModalContents };
