import styled from 'styled-components';
import { color} from '../../utils/styles';

export const StyledIcon = styled.i`
  font-family: "icomoon" !important;
  speak: never;
  font-size: ${props => `${props.size}px`};
  color: ${props => `${props.color}`};
  color: ${props => color[props.color]};
  display:${props => (props.hide ? 'none' : 'unset')};
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 &:before {
    content: "${props => props.code}";
 }
`;
