import styled from "styled-components/macro";
import { color } from "shared/utils/styles";


export const StyledTitle = styled.h1`
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 10px;
`;


export const StyledSectionMotivation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;

  color: ${color.primary};

  & h1 {
    font-weight: lighter;
  }

  @media (max-width: 515px) {
    flex-direction: column;
  }
`;

export const StyledSmallText = styled.h3`
  font-size: 0.8rem;
  margin-top: -10px;
  font-weight: 100;
  padding-bottom: 10px;
`;
