import React, { createContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useLogoutMutation,
  useUserInfoQuery,
} from "../Features/auth/auth-api-slice";

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const [userLogout] = useLogoutMutation();
  const location = useLocation();
  const { data: user, error } = useUserInfoQuery("", {
    skip: location.pathname === "/Auth" ? true : false,
  });

  const navigate = useNavigate();

  const [authState, setAuthState] = useState({
    userInfo: null,
    isAuthenticated: false,
  });


  useEffect(() => {
    if (user) {
      setAuthState({
        userInfo: user,
        isAuthenticated: true,
      });
    }
  }, [user]);

  const setAuthInfo = ({ userInfo }) => {
    setAuthState({
      userInfo,
      isAuthenticated: userInfo && userInfo._id ? true : false,
    });
  };

  const logout = async () => {
    const LogOutResponse = await userLogout()
      .unwrap()
      .catch((error) => console.log("error=>", error));

    if (LogOutResponse?.status === "success") {
      setAuthState({
        userInfo: {},
        isAuthenticated: false,
      });
      navigate("/");
    }
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: (authInfo) => setAuthInfo(authInfo),
        logout,
        error,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
