import styled from "styled-components/macro";
import { sizes } from "shared/utils/styles";


export const StyledWrapper=styled.div`
max-width:${sizes.Primarycontainer+"px"};
margin:0 auto;
`

export const TabList = styled.select`
    display: flex;
    margin-right:auto;
    margin-left:20px;
    border-radius:10px;
    overflow:hidden;
    background-color:#000000;
    padding:6px 15px;
    max-width:25%;
    color:#fff;
`;
export const TabContent = styled.div`
    display: flex;
`;
export const TabLink = styled.option`
color:#fff;
    
`;
export const TabPane = styled.div`
    margin-top:20px;
    width:100%;
`;

export const ItemThumbnail = styled.img`
    max-width:100%;
    display:block;
`
export const ItemName = styled.p`
    padding: 5px;
    text-align: center;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
`;
export const InnerContent = styled.div`
    border:2px solid #404446;
    border-radius:10px;
    overflow:hidden;
    cursor:pointer;
`

export const NoItems = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    width:100%;
    height:100%;
    padding: 80px 0px;
    background: #151618;
    margin: 0px 15px;
    border-radius: 20px;
    font-size:20px;
`
export const SelectWrapper = styled.div`
margin-left:-15px;
`