import styled from "styled-components/macro";
import { color } from "shared/utils/styles";
import Card from "shared/components/Card";
import rtl from "styled-components-rtl";
import { GridWrap as libGridWrap } from "shared/components/lib";


export const StyledWrapper = styled.div`
    padding-top:10px;
`;

export const GridWrap = styled(libGridWrap)`
  column-gap: 26px;
    row-gap: 53px;
    height:fit-content;
    justify-content: center;
    padding-top: 22px;
    grid-template-columns: repeat(auto-fit,201px);
`;

export const StyledGridItem = styled(Card)`
display:flex;
box-shadow:unset;
  border: ${(props) =>
    props.isDone
      ? `2px solid ${color.primary}`
      : `2px solid ${color.transparentGray45}`};
  background: ${color.backgroundDarkPrimary};
  height: 305px;
  position: relative;
  margin: 0;
  &:hover {
    cursor: auto;
    box-shadow: revert;
  }

`;

export const Stylednumber = styled.div`
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 999px;
  color: ${(props) =>
    props.isDone ? `${color.primary}` : `${color.transparentGray45}`};
  background: ${color.backgroundDarkPrimary};
  border: 2px solid
    ${(props) =>
      props.isDone ? `${color.primary}` : `${color.transparentGray45}`};
  position: absolute;
  top: -22px;
  display: flex;
  font-size:22px;
  justify-content: center;
  align-items: center;
  ${rtl`
  left: 50%;
    transform: translateX(-50%);
  `}
`;
