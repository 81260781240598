import { useCourseQuery } from "Features/course/course-api-slice";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function useGetUserCourses() {

const {t}=useTranslation()
  const { data: courses, isLoading, error } = useCourseQuery();
  const [courseOptions, SetCourseOptions] = useState([
    { _id: "", name: t("misc.selectCourse")},
  ]);

  useEffect(() => {
    if (courses)
      SetCourseOptions(prevCourseOptions => [...prevCourseOptions, ...courses]);
  }, [courses]);

  return { courseOptions, isLoading, error };
}

export default useGetUserCourses;
