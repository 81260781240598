/* ###THIS ICON ARE AVAILABLE IN THIS URL:
https://icomoon.io/app/#/select/font

question: how is the icons work?
answer: they are used as fonts (inside fontstyles.css)
you only need to choose the icon of your desire from https://icomoon.io/app/#/select/font.

question:how to activate the icon i want from the website?
answer: simply place the content code and the icon name inside the
fontIconCodes object,
*/

export const fontIconCodes = {
  home: "\\e900",
  home2: "\\e901",
  home3: "\\e902",
  office: "\\e903",
  music: "\\e911",
  envelop: "\\e945",
  phone: "\\e942",
  lock: "\\e98f",
  menu: "\\e9bd",
  cross: "\\ea0f",
  "circle-up": "\\ea41",
  "circle-down": "\\ea43",
  cog: "\\e994",
  play3: "\\ea1c",
  play2: "\\ea15",
  bug: "\\e999",
  book: "\\e91f",
  lab: "\\e9aa",
  project: "\\e926",
  info: "\\ea0c",
  arrow: "\\ea3a",
  badge: "\\e90c",
  "video-camera": "\\ebfa",
  edit: "\\ef00",
  "star-empty": "\\e9d7",
  "star-full": "\\e9d9",
  "star-empty2": "\\ef6b",
  warning: "\\ed52",
  "checkmark-outline": "\\eb2f",
  "back": "\\ea40",
  "checkbox-unchecked":"\\ea53",
  "checkbox-checked":"\\ea52"
};
