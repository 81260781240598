import { StyledCard } from "./Styles";

const Card = ({
  variant="backgroundMedium",
  children,
  width = "auto",
  height,
  className,
  onClick = undefined,
  ...rest
}) => {
  return (
    <StyledCard
      width={width}
      height={height}
      className={className}
      variant={variant}
      {...rest}
      onClick={onClick}
    >
      {children}
    </StyledCard>
  );
};

export default Card;
