import React from "react";
import {
  StyledWelcomeBanner,
  Heading,
  RightContent,
  LeftContent,
  Content,
} from "./Styles";
import {
  Modal,
  ModalContents,
  ModalOpenButton,
} from "shared/components/Modal/Modal";
import Button from "shared/components/Button/Button";
import { useTranslation } from "react-i18next";
import UploadProjectsModal from "Platform/Common/Modals/UploadProjectsModal";


const WelcomeBanner = () => {
  const { t } = useTranslation();

  return (
    <StyledWelcomeBanner>
      <LeftContent>
        <Heading>Welcome to Lab</Heading>
        <Content>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit
          perferendis iusto, placeat, quis assumenda magnam quidem accusantium
          ipsam ipsum nam expedita ex repellendus eveniet sint quibusdam fuga
          maiores. Voluptate, eius.
        </Content>
      </LeftContent>
      <RightContent>
        <Content>Submission of labs tasks</Content>
        <Modal>
          <ModalOpenButton>
            <Button className="btn_glow">Upload</Button>
          </ModalOpenButton>
          <ModalContents
            size="lg"
            height="auto"
            aria-label="upload labs"
            title={t(`contentBoxAction.LABS`)}
          >
            <UploadProjectsModal />
          </ModalContents>
        </Modal>
      </RightContent>
    </StyledWelcomeBanner>
  );
};

export default WelcomeBanner;
