import React from "react";
import { uploads } from "playground/coursesPath";
import {
  StyledWrapper,
  GridWrap,
  StyledGridItem,
  Stylednumber,
} from "./Styles";
import UploadContentBox from "./UploadContentBox/UploadContentBox";

export default function UploadItems() {
  return (
    <StyledWrapper>
      <GridWrap width="202px">
        {uploads.map((upload, i) => (
          <StyledGridItem isDone={upload.isDone} key={upload._id}>
            <Stylednumber isDone={upload.isDone}>
              <h5>{upload.number}</h5>
            </Stylednumber>
            <UploadContentBox isDone={upload.isDone} name={upload.name}/>
          </StyledGridItem>
        ))}
      </GridWrap>
    </StyledWrapper>
  );
}
