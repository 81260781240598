import { emptySplitApi } from "../emptySplit-api";


export const authApi = emptySplitApi.injectEndpoints({

  endpoints: (builder) => ({
    login: builder.mutation({
        query: (credentials) => ({
          url: 'AuthService/login',
          method: 'POST',
          body: credentials,
        }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: 'AuthService/logout',
        method: 'POST',
      }),
  }),
  userInfo: builder.query({
    query: () => `AuthService/user-info`,
  }),
  }),
});

export const { useLoginMutation, useLogoutMutation, useUserInfoQuery  } = authApi;
