import styled from 'styled-components/macro';
import { color} from '../../utils/styles';
import Spinner from '../Spinner';

export const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  vertical-align: middle;
  line-height: 1;
  white-space: nowrap;
  border-radius: 3px;
  font-size:16px;
  transition: all 0.1s;
  background: ${props => color[props.variant]};
  appearance: none;
  &:hover{
      cursor: pointer;
      filter: brightness(85%);
    }
  &:disabled {
    opacity: 0.6;
    
    &:hover{
      cursor: not-allowed;
    }
    
  }

  &.btn_glow{
    border:1px solid ${color.primary};
    color: ${color.primary};
    border-radius: 8px;
    &:hover{
     background:${color.primary};
     color:white;
    }
  }

  &.btn_grey{
    border:1px solid ${color.transparentGray45};
    color: ${color.white};
    border-radius: 8px;
    &:hover{
     background:${color.transparentGray45};
     color:${color.white};
    }
  }

  &.blank{
    border:none;
    color: ${color.primary};
    &:hover{
     text-decoration:underline;
     filter:brightness(1.5);
    }
  }
   
`;

export const StyledSpinner = styled(Spinner)`
  position: relative;
  top: 1px;
`;