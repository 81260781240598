import styled from "styled-components/macro";
import { color,sizes } from "shared/utils/styles";
import play from "App/assets/img/play.png";

export const TitleSkeleton = styled.div`
  background: #6f6f6f;
  width: 80%;
  height: 1rem;
  margin: 4px auto;
  border-radius: 15px;
  line-height: 1.6;
`;

export const ImgWrapper = styled.div`
  height: auto;
  display: block;
  position: relative;
  background: ${color.transparentGray45};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
`;

export const PlayButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: transform 150ms ease-in-out;
  background: url(${play});
  transform: scale(0.8);
  ${ImgWrapper}:hover & {
    opacity: 1;
    transform: scale(1.2);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: auto 100%;
  }
`;


export const GridWrap = styled.div`
--min-column-width:min(${props=>props.width},100%);
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fit,202px);  
`


export const StyledCenterSpinner=styled.div`
  position: "fixed";
  top: 50%;
  left:50%;
  transform:translate(-50%, -50%);
`

export const ResponsiveCardContainer =styled.div`
max-width:${sizes.Primarycontainer+"px"};
margin:0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap:3rem;
}
@media (max-width: 767px) {
max-width:300px;
  }
` 

export const ErrorText = styled.h3`
color:red;
font-size:${props=>props.size};
padding:15px;
`;
