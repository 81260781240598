import styled from "styled-components/macro";
import { color, mixin } from "shared/utils/styles";
import rtl from "styled-components-rtl";

export const StyledGridTable = styled.div`
  display: grid;
  padding: 0;
  margin: -10px;
  grid-template-columns: min(120px, 80%) 1fr;
  grid-template-rows: 55px 55px 1fr;
  text-align: start;

  & > * {
    padding: 15px 10px;

    font-size: 0.9rem;
  }
  & > *.tableItems:nth-child(-n + 4) {
    border-bottom: 1px solid grey;
    border-radius: none;
  }
  & > *.tableItems:nth-child(odd) {
    border-right: 1px solid grey;
    border-radius: none;
  }

  & > .styledUl {
    text-align: start;
    margin: 15px;

    & > li {
      padding-inline-start: 14px;
      &::after {
        ${rtl`
        left:-4px;
    `}
      }
    }
  }

  @media (max-width: 700px) {
    & > .actionColumn {
      grid-column: 1/-1;
    }
    & > .actions {
      display: none;
    }
  }
`;

export const StyledActionColumn = styled.div`
  display: grid;
  row-gap: 15px;
  justify-items: start;

  & > h1 {
    font-size: 0.9rem;
  }
  & > span {
    font-size: 1.1rem;
    color: ${color.transparentGray45};
  }
`;

export const StyledTopSection = styled.div`
  display: flex;
  gap: 25px;
  margin-bottom: 20px;
  & .imgWrapper > img {
    width: 300px;
    height: auto;
  }
  @media (max-width: 728px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`;

export const StyledTextWrapper = styled.div`
  & > h1 {
    font-size: 1.2rem;
    color: ${color.primary};
  }
  & > p {
    height: 200px;
    ${mixin.scrollableY};
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;
