import thumbnail from 'App/assets/img/courses/Forensics.webp'
export const labs = [
    {
        thumbnail: thumbnail,
        text: 'Hack me back',
        _id:"#$#$24"
    },
    {
        thumbnail: thumbnail,
        text: 'is simply dummy text of',
        _id:"#$#$24s"
    },
    {
        thumbnail: thumbnail,
        text: 'Neque porro quisquam est qui ',
        _id:"#$#$2dffsf4"
    },
    {
        thumbnail: thumbnail,
        text: 'The cyber news',
        _id:"#$#$24fdsfdfsfdsf"
    },
    {
        thumbnail: thumbnail,
        text: 'Hack me back',
        _id:"#$#$2sss4"
    },
    {
        thumbnail: thumbnail,
        text: 'is simply dummy text of',
        _id:"#$#$2ddsa4"
    },
    {
        thumbnail: thumbnail,
        text: 'Neque porro quisquam est qui ',
        _id:"#$#$2dfdsfffff4"
    },
    {
        thumbnail: thumbnail,
        text: 'The cyber news',
        _id:"#$#$2dfddsssss4"
    }
]