import { useState,useEffect } from 'react';

import{ThemeProvider} from 'styled-components'
import NormalizeStyles from './NormalizeStyles'
import BaseStyles from './BaseStyles';
import Routes from './Routes';
import Toast from '../App/Toast'
import cookies from 'js-cookie'
import { useDispatch } from "react-redux";
import { csrf } from "Features/csrf/csrf-slice";
import Axios from 'axios';

// We're importing .css because @font-face in styled-components causes font files
// to be constantly re-requested from the server (which causes screen flicker)
// https://github.com/styled-components/styled-components/issues/1593
import './fontStyles.css'

function App() {
  const [dir, setdir] = useState(cookies.get('i18next')==="he"?"rtl":"ltr" )
  const URL = process.env.REACT_APP_BACKEND_URL
  const dispatch = useDispatch()

  useEffect(()=>{
    Axios({
      method: "get",
      withCredentials: true,
      baseURL: `${URL}AuthService/csrf-token`,
      headers: {
        Accept: "*/*"
      }
    })
    .then((resp) => {
      dispatch(csrf(resp.data.csrfToken))
    })
  },[dispatch,URL])


  return (
    <>
    <ThemeProvider theme={{dir,setdir}}>
   <NormalizeStyles/>
    <BaseStyles/>  
    <Toast />
    <Routes/>
    </ThemeProvider>
    </>
  );
}

export default App;
