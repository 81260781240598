import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes as RouterRoutes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";

import { AuthProvider, AuthContext } from "context/AuthContext";
import Labs from "Platform/Pages/Labs/Labs";

const Platform = React.lazy(() => import("Platform"));
const Dashboard = React.lazy(() => import("Platform/Pages/Dashboard"));
const MyLearning = React.lazy(() => import("Platform/Pages/MyLearning"));
const MyLearningProgress = React.lazy(() =>import("Platform/Pages/MyLearning/MyLearningProgress"));
const Watch = React.lazy(() => import("Platform/Pages/Watch"));
const Auth = React.lazy(() => import("Auth/Auth"));
const AccountSettings = React.lazy(() =>import("Platform/Pages/AccountSettings"));
const PageError = React.lazy(() => import("shared/components/PageError"));

function RequireAuth() {
  const { authState, error } = useContext(AuthContext);

  // if (!authState.userInfo && error) {
  //   return <Navigate to="/Auth" />;
  // }

  return <Outlet />;
}

const Routes = () => {
  return (
    <Router>
      <AuthProvider>
        <RouterRoutes>
          <Route path="/" element={<Navigate replace to="/Auth" />} />
          <Route path="/Auth" element={<Auth />} />
          <Route element={<RequireAuth />}>
            <Route path="/platform" element={<Navigate to="dashboard" />} />
            <Route path="/platform/*" element={<Platform />}>
              <Route path="labs" element={<Labs />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="dashboard/watch/:id" element={<Watch />} />
              <Route path="accountSettings" element={<AccountSettings />} />
              <Route path="mylearning" element={<MyLearning />} />
              <Route path="mylearning/watch/:id" element={<Watch />} />
              <Route
                path="mylearning/mylearningProgress"
                element={<MyLearningProgress />}
              />
            </Route>
          </Route>
          <Route path="*" element={<PageError />} />
        </RouterRoutes>
      </AuthProvider>
    </Router>
  );
};

export default Routes;
