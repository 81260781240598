import styled from "styled-components/macro";
import Button from "shared/components/Button";
import {color} from 'shared/utils/styles'

export const StyledBoxWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 85%;
  height: 85%;
  display: grid;
  text-align: center;
  grid-gap:14px;
`;

export const StyledTopWrapper = styled.div`
  cursor: pointer;
  justify-self: end;
  display: flex;
`;

export const StyledName= styled.div`
height:35px;
color:${props=>props.isDone? (`${color.primary}`) : "white"};
`;

export const StyledDoneTask= styled.h3`
color:${color.primary}
`;


export const StyledChooseFile = styled(Button)`
  height: 32px;
`;
export const StyledUploadSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  &>h5 {
    font-size: 0.9rem;
    font-weight: 100;
    align-self: center;
    padding-top:5px;
  }
`;
