import styled from "styled-components/macro";
import { color } from "shared/utils/styles";
import Button from "shared/components/Button/Button";
import rtl from "styled-components-rtl";

export const StyledModalCard = styled.div`
  font-size: 0.8rem;
  background: ${color.backgroundDarkPrimary};
  border-radius: 15px;
  list-style-type: none;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > h4 {
    font-size: 1.1rem;
  }
  & > p {
    line-height: 1.5;
    font-size: 0.9rem;
    max-width:500px;
    margin:0 auto;
  }
  & > h4 > strong {
    color: ${color.primary};
  }
`;

export const StyledBottomContainer=styled.div`
display:grid;
width:min(100%,300px);
gap:15px;
justify-items:center;
margin:0 auto;
padding-top:15px;
&>*{
  color:${color.primary};
    font-weight:bold;
}
&>h1{
    font-size:1.5rem;
}
&>h2{
    font-size:1.2rem;
}
& > h5  {
    font-size: .8rem;
  }
`


export const StyledImgWrapper=styled.div`
border-radius:15px;
width:min(100%,${props=> props.width});
background:${props=> props.background};
height:auto;
margin:0 auto;
margin-bottom:25px;
&>img{
    width:100%;
}
`

export const StyledChooseFile = styled(Button)`
  height: 32px;
`;
export const StyledUploadSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  &>h5 {
    font-size: 0.9rem;
    font-weight: 100;
    align-self: center;
    padding-top:5px;
  }
`;

export const StyledUl = styled.ul`
  font-size: 0.8rem;
  background: ${props=>props.background? color[props.background]:`${color.backgroundDarkPrimary}`};
  border-radius: 15px;
  list-style-type: none;
  padding: 10px 0px 10px 0px;
  padding-inline: 22px;

  & > li {
    position: relative;
    padding-inline-start: 10px;
    line-height: 1.5;
    &::after {
      content: "";
      position: absolute;
      top: 5px;
      background: ${color.primary};
      width: 10px;
      height: 10px;
      border-radius: 50%;
      ${rtl`
        left:-10px;
    `}
    }
  }
`;