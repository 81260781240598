import React, { useState } from "react";
import styled from "styled-components/macro";
import { COLORS, WEIGHTS } from "shared/constants/generalConsts";
import Icon from "./../Icon/Icon";

const NoFormSelect = ({
  label,
  value,
  children,
  className = undefined,
  ...delegated
}) => {
  const [rotateChevron, SetRotateChevron] = useState(false);

  const childArray = React.Children.toArray(children);
  const selectedChild = childArray.find((child) => child.props.value === value);

  const displayedValue =
    selectedChild && selectedChild.props && selectedChild.props.children
      ? selectedChild.props.children
      : "";

  return (
    <Wrapper className={className} onClick={()=>SetRotateChevron(rotateChevronPrev=>!rotateChevronPrev)}>
      <VisibleLabel>{label}</VisibleLabel>

      <SelectWrapper>
        <NativeSelect {...delegated}>{children}</NativeSelect>

        <DisplayedBit>
          {displayedValue}

          {rotateChevron ? (
            <ChevronIcon iconName="circle-up" />
          ) : (
            <ChevronIcon iconName="circle-down" />
          )}
        </DisplayedBit>
      </SelectWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.label`
  display: flex;
  align-items: center;
`;

const VisibleLabel = styled.span`
  color: ${COLORS.gray[700]};
  margin-right: 16px;
`;

const SelectWrapper = styled.div`
  position: relative;
`;

const NativeSelect = styled.select`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const DisplayedBit = styled.span`
  display: block;
  background: transparent;
  font-size: 1rem;
  font-weight: ${WEIGHTS.medium};
  color: #b1b1b1;
  padding: 9px 42px 9px 9px;
  border-radius: 8px;
  pointer-events: none;
  height: 42px;
  border: 1px solid #686868;
  min-width: min(200px, 100%);
  ${NativeSelect}:focus ~ & {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }

  @media (max-width: 434px) {
    padding: 9px 0px 0px 0px;
  }
`;

const ChevronIcon = styled(Icon)`
  color: #b1b1b1;
  position: absolute;
  top: 0px;
  right: 0;
  bottom: -8px;
  margin: auto;
  width: 24px;
  height: 24px;
`;

export default NoFormSelect;
