import styled from "styled-components/macro";
import { color} from "shared/utils/styles";

export const StyledCard = styled.div`
  background: ${(props) => color[props.variant]};
  height: ${(props) => props.height};
  margin: 1rem;
  width: ${(props) => props.width};
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 15px;
  &:hover {
    box-shadow: 0 0 1px 1px hsl(154deg 58% 51%), 0 3px 1px 0 hsl(154deg 58% 51% / 21%);
  }
`;










