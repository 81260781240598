import styled from "styled-components/macro";

export const StyledWelcomeBanner = styled.div`
  padding: 20px;
  padding-right: 0px;
  background-color: #151618;
  margin: auto;
  margin-top: 20px;
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
export const Heading = styled.h2`
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 10px;
`;
export const RightContent = styled.div`
  flex: 0 0 calc(100% / 12 * 3);
  text-align: center;
  p {
    margin-right: 0px;
  }
`;
export const LeftContent = styled.div`
  flex: 0 0 calc(100% / 12 * 9);
  padding-right: 40px;
  border-right: 1px solid #343434;
  margin-right: auto;
`;
export const Content = styled.p`
  margin-bottom: 20px;
`;
