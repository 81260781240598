import React from "react";
import { StyledModalCard, StyledUl } from "../lib";
import {
  StyledGridTable,
  StyledActionColumn,
  StyledTopSection,
  StyledButtonContainer,
  StyledTextWrapper,
} from "./Styles";
import Button from "shared/components/Button/Button";
import thumbnail from "App/assets/img/courses/Forensics.webp";

const LabsModal = ({ img }) => {
  return (
    <>
      <StyledTopSection>
        <div className="imgWrapper">
          <img src={thumbnail} alt="lab"></img>
        </div>
        <StyledTextWrapper>
          <h1>Welcome to the world’s leading car rental website.</h1>
          <p>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat duis enim velit mollit. Exercitation
            veniam consequat sunt nostrud amet.Amet minim mollit non deserunt
            ullamco est sit aliqua dolor do amet sint. Velit officia consequat
            duis enim velit mollit. Exercitation veniam consequat sunt nostrud
            amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do
            amet sint. Velit officia consequat duis enim velit mollit.
            Exercitation veniam consequat sunt nostrud amet.Amet minim mollit
            non deserunt ullamco est sit aliqua dolor do amet sint. Velit
            officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco
            est sit aliqua dolor do amet sint. Velit officia consequat duis enim
            velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet
            minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
            Velit officia consequat duis enim velit mollit. Exercitation veniam
            consequat sunt nostrud amet.
          </p>
        </StyledTextWrapper>
      </StyledTopSection>
      <StyledModalCard>
        <StyledGridTable>
          <h1 className="tableItems">Project Name</h1>
          <h1 className="tableItems">Car Rental</h1>
          <h1 className="tableItems">Technologies</h1>
          <h1 className="tableItems">
            in this lab we used the following technologies
          </h1>
          <h1 className="tableItems actions">Actions</h1>

          <StyledActionColumn className="actionColumn">
            <h1>View a list of security vulnerabilities</h1>
            <StyledUl
              background="backgroundMedium"
              className="tableItems styledUl"
            >
              <li>
                Cross Site Request Forgery - Creating a link that will cause the
                customer to perform an unwanted action
              </li>
              <li>
                JWT Authentication Bypass - Bypass the JWT mechanism in the
                system.
              </li>
              <li>
                Parameter tampering - performing unwanted actions and accessing
                information of another user
              </li>
              <li>
                Cross Site Scripting - Injecting code into a site that will
                cause an attacker to steal accounts from the system
              </li>
            </StyledUl>
            <StyledButtonContainer>
              <Button disabled className="btn_grey">
                Watch Lab Solution
              </Button>
              <Button className="btn_glow">Start Lab</Button>
            </StyledButtonContainer>
            <span>
              *Watch Lab solutions will only available once you upload lab task.
            </span>
          </StyledActionColumn>
        </StyledGridTable>
      </StyledModalCard>
    </>
  );
};

export default LabsModal;
