import { emptySplitApi } from "../emptySplit-api";

export const labsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    labsByCourse: builder.mutation({
      query: (courseId) => ({
        url: `api/v1/labs/by-course`,
          method: 'POST',
          body: {
            courseId: courseId
          }
        })
    }),
  })
});

export const { useLabsByCourseMutation} = labsApi;
