import React from "react";
import {
  StyledWrapper,
  TabContent,
  TabPane,
  ItemName,
  ItemThumbnail,
  InnerContent,
  NoItems,
  SelectWrapper,
} from "./Styles";
import { useState } from "react";
import { labs as mockLabs } from "playground/labs";
import { StyledTitleNoFormSelect } from "shared/components/HeaderTitle/Styles";
import { useLabsByCourseMutation } from "Features/labs/labs-api-slice";
import WelcomeBanner from "./WelcomeBanner";
import Card from "shared/components/Card";
import { ResponsiveCardContainer } from "shared/components/lib";
import ModalContainer from "./ModalContainer";
import useGetUserCourses from "shared/utils/hooks/useGetUserCourses";


export default function Labs() {
  const [selectedCourse, setCourse] = useState("");
  const [labs, setLabs] = useState([]);

  const [getLabs] = useLabsByCourseMutation();
  const {courseOptions,isLoading, error }=useGetUserCourses()

  const courseChanged = async (courseId) => {
    setCourse(courseId);
    const labsResponse = await getLabs(courseId)
      .unwrap()
      .catch(() => {});
    if (labsResponse && labsResponse.success) {
      setLabs(labsResponse.list);
    } else {
      setLabs(mockLabs);
    }
  };
  return (
    <StyledWrapper>
      <SelectWrapper>{
        error && <h1>error loading content please refresh</h1>
      }
        {!isLoading && courseOptions && (
          <StyledTitleNoFormSelect
            label=""
            value={selectedCourse}
            onChange={(ev) => courseChanged(ev.target.value)}
          >
            {courseOptions.map((course) => (
              <option value={course._id}>{course.name}</option>
            ))}
          </StyledTitleNoFormSelect>
        )}
      </SelectWrapper>
      <WelcomeBanner />
      <TabContent>
        <TabPane>
          <ResponsiveCardContainer>
            {labs.length ? (
              labs.map((lab) => (
                <Card key={lab._id} width={"230px"} height="auto">
                <ModalContainer img={lab.thumbnail} id={lab._id} title={lab.text}>
                  <InnerContent>
                    <ItemThumbnail src={lab.thumbnail} alt="" />
                    <ItemName>{lab.text}</ItemName>
                  </InnerContent>
                  </ModalContainer>
                </Card>
              ))
            ) : (
              <NoItems>There are no labs found for the selected course</NoItems>
            )}
          </ResponsiveCardContainer>
        </TabPane>
      </TabContent>
    </StyledWrapper>
  );
}
