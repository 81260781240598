export const coursesPath = [
  {
    src: "assets/img/stickers/08B.png",
    name: "Active directory",
    description:
      "Etiam eu dapibus tortor. Mauris vitae malesuada orci. Suspendisse et eros placerat",
    completed: 70,
    color: "hsl(358deg 79% 35%)",
    hours: 11,
    level: "advanced",
    _id: "454353dfdsfdsfdsfssf",
    progress: "50",
  },
  {
    src: "assets/img/stickers/09B.png",
    name: "Attack Python",
    description: "Etiam eu dapibus tortor. dfdsfs fsdfds fdef sdfhgh hfgf d",
    completed: 20,
    color: "hsl(198deg 71% 45%)",
    hours: 21,
    level: "begginer",
    _id: "454353dfdsdfdsfsfff",
    progress: "50",
  },
  {
    src: "assets/img/stickers/1-Pentesting Infrastructure.png",
    description:
      "cursus dignissim, urna tellus lobortis eros, in rutrum arcu odio at ligula. Integer vel libero turpis.",
    name: "C#",
    completed: 30,
    color: "hsl(358deg 79% 35%)",
    hours: 23,
    level: "begginer",
    _id: "454353dfdfdsfssf",
    progress: "64",
  },
  {
    src: "assets/img/stickers/Linux.png",
    description:
      "ndimentum et et magna. Vestibulum ut mattis nulla. Cras vel mi consectetur, semper dui eget, maximus urna. Sed eleifend eros non auctor imperdiet.",
    name: "Linux",
    completed: 70,
    color: "hsl(51deg 95% 48%)",
    hours: 3,
    level: "begginer",
    _id: "454353dfdsfdff",
    progress: "0",
  },
  {
    src: "assets/img/stickers/Web Application.png",
    description:
      "ndimentum et et magna. Vestibulum ut mattis nulla. Cras vel mi consectetur, perdiet.",
    name: "Forensics",
    color: "hsl(72deg 96% 37%)",
    completed: 70,
    hours: 6,
    level: "intermidiate",
    _id: "454353dfdsf",
    progress: "98",
  },
];

export const coursesContent = [
  {
    _id: "4354grhgfdggh",
    number:1,
    isDone:true,
    name: "VIDEO_BOX",
    status:"COMPLETED"
  },
  {
    _id: "4354grghgh",
    number:2,
    isDone:true,
    name: "INTERNAL_EXAM",
    status:"COMPLETED"
  },
  {
    _id: "4354grshgh",
    number:3,
    isDone:true,
    name: "PROJECT_MIDDLE",
    status:"COMPLETED"
  },
  {
    _id: "4354ggfdgrhgh",
    number:4,
    isDone:false,
    name: "PROJECT_FINAL",
    status:"FAILED"
  },
  {
    _id: "43434g5224grhgh",
    number:5,
    isDone:true,
    name: "INTERNATIONAL_CERT",
    status:"NOT_STARTED"
  },
  {
    _id: "43434g54grhdsgh",
    number:6,
    isDone:true,
    name: "LABS",
    status:"COMPLETED"
  },
  {
    _id: "43434g54grhgh",
    number:7,
    isDone:false,
    name: "COURSE_COMPLETION",
    status:"PENDING"
  },
];


export const uploads = [
  {
    atLecture:22,
    _id: "4354grhgfdggh",
    number:1,
    isDone:true,
    name: "filezila game"
  },
  {
    atLecture:12,
    _id: "4354grghgh",
    number:2,
    isDone:true,
    name: "learning graphql and many more fd fd fd fd"
  },
  {
    atLecture:2,
    _id: "4354grshgh",
    number:3,
    isDone:true,
    name: "hack the bank"
  },
  {
    atLecture:7,
    _id: "4354ggfdgrhgh",
    number:4,
    isDone:false,
    name: "tasks importantro",
    status:"FAILED"
  },
  {
    atLecture:12,
    _id: "43434g5224grhgh",
    number:5,
    isDone:false,
    name: "bla bli blu",
    status:"NOT_STARTED"
  },
  {
    atLecture:18,
    _id: "43434g54grhgh",
    number:6,
    isDone:false,
    name: "nandaka",
    status:"PENDING"
  },
];

export const feedback = 
  {
    rank: 3,
    title:"overall Projects Feedback",
    text:"project was good but please send again project 1 and 4",
  }
;