import React,{Suspense} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from 'react-redux'
import {store} from './App/store'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import Spinner from './shared/components/Spinner'
import {StyledCenterSpinner} from 'shared/components/lib'

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'pt', 'he'],
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/locales/{{lng}}/translations.json',
    },
  })



ReactDOM.render(
  <Suspense fallback={<StyledCenterSpinner><Spinner size={96}/></StyledCenterSpinner>}>
  <React.StrictMode>
   <Provider store={store}>
   <App /> 
    </Provider>
  </React.StrictMode>
  </Suspense>
  ,
  document.getElementById('root')
);

